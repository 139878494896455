@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');


* {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
}

body {
  overflow: hidden;
  /* overflow-x: hidden; */
}

.App {
  background-color: #F7F9FC;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

h1 {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

/* Dashboard */
.dashboard {
  position: relative;
  /* position: absolute; */
  background-color: #F0F0F0;
  width: 100vw;
  height: 100vh;
}

.dashboard-content-container {
  position: relative;
  display: flex;
  flex-direction: row;
  margin-left: 78px;
  height: 100%;
  width: 100%;
}

/* Navbar */
.navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  height: 76px;
  width: 100vw;
  left: 0px;
  right: 0px;
  top: 0px;
  background-color: #F7F9FC;
}

.logo {
  display: flex;
  flex-direction: row;
  align-items: center;

  position: absolute;
  width: 197px;
  height: 40px;
  left: 32px;
  top: 18px;
}

.logo:hover {
  cursor: pointer;
  filter: drop-shadow(0px 6px 8px rgba(0, 0, 0, 0.45));
}

/* .search {
  width: 33vw;
  margin: 15px 0px;
  padding: 0;
} */

/* .search > input {
  width: 100%;
  height: 100%;
  padding: 0;
} */

/* .dropdown {
  background-color: #F9F9F9;
  display: flex;
  flex-direction: column;
  border: 1px solid gray;
  position: absolute;
  cursor: pointer;
  width: 33vw;
} */

/* .dropdown-row{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #F9F9F9;
  z-index:10;
} */

/* .dropdown-row:hover {
  background-color: #e1e1e1;
} */

.log {
  position: absolute;
  width: 40px;
  height: 40px;
  right: 32px;
  top: 18px;
}

.log:hover {
  filter: drop-shadow(0px 6px 8px rgba(0, 0, 0, 0.45));
}

/* FOOTER */

footer {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #F7F9FC;
  height: 100px;
  width: 100%;
  margin: 0px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  
}

footer > div > a {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #076AE0;
  margin: 15px 0px 0px 0px;
}

/* HOMEPAGE */

.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100vw;
}
.image-container {
  position: relative;
}
.image-container > .background-image {
  position: absolute;
  right: -591px;
  top: -400px;
  z-index: 0;
}

.image-container >.boy-image {
  position: absolute;
  right: 380px;
  top: -210px;
  z-index: 1;
}

.image-container >.girl-image {
  position: absolute;
  right: -565px;
  top: -275px;
  z-index: 1;
}

.home > h1 {
  margin-top: 144px;
  width: 563px;
  height: 144px;
  font-size: 64px;
  line-height: 72px;
  text-align: center;
  color: #252930;
  text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
  z-index: 1;
}

.home > h4 {
  margin-top: 12px;
  margin-bottom: 48px;
  width: 563px;
  height: 32px;
  font-size: 28px;
  line-height: 32px;

  text-align: center;

  color: #D7840B;

  text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
  z-index: 1;
}

.signin-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 72px;
  gap: 8px;

  width: 259px;
  height: 64px;
  left: 590px;
  top: 492px;

  background: #0B5DD7;

  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.24);
  border-radius: 8px;
  border-width: 0px;
  z-index: 1;
}
.signin-button:hover {
  cursor: pointer;
  background-color: #07306D
}

.signin-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 259px;
  height: 64px;

  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;

  color: #F7F9FC;

  flex: none;
  order: 1;
  flex-grow: 0;
}

.center > .background {
  position: absolute;
}

/* LOGIN */

.faded-background {
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 2;
}

.login {
  position: absolute;
  width: 487px;
  height: 423px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 3;
  background-color: #FFFFFF;
  box-shadow: 7px 9px 12px rgba(0, 0, 0, 0.25);
  border-radius: 24px;
  margin-top: 136px;
}

.login > form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login > h4 {
  margin-top: 64px;
  margin-bottom: 29px;
  width: 70px;
  height: 32px;

  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: #000000;
}

.login > form > p {
  margin-top: -5px;
  margin-bottom: 32px;
  width: 151px;
  height: 12px;
  left: 168px;
  top: 282px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-align: center;

  color: #000000;
}

.login > form > p > span {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  color: #007AFF;
}

.login > form > p > span:hover {
  cursor: pointer;
}

.login-button {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;

  color: #F7F9FC;

  width: 343px;
  height: 33px;

  background: #0B5DD7;
  border-radius: 8px;

  border: none;
}

.login-button:hover {
  cursor: pointer;
  background-color: #07306D
}

.signup {
  position: absolute;
  width: 487px;
  height: 753px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 3;
  background-color: #FFFFFF;
  box-shadow: 7px 9px 12px rgba(0, 0, 0, 0.25);
  border-radius: 24px;
  margin-top: 4px;
}

.signup > form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.signup > h4 {
  margin-top: 96px;
  margin-bottom: 48.5px;
  width: 229px;
  height: 32px;

  font-size: 28px;
  line-height: 32px;

  display: flex;
  align-items: center;

  color: #000000;
}

.signup-bottom {
  display: flex;
  flex-direction: row;
  align-items:center;
  justify-content: space-evenly;
  width: 437px;
  margin-top: 15.5px;
}

.signup-bottom > p {
  width: 146px;
  height: 30px;
  left: 92px;
  top: 645px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;

  color: #000000;
}

.signup-bottom > p > span {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #007AFF;
}
.signup-bottom > p > span:hover {
  cursor: pointer;
}

.signup-button {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  
  color: #F7F9FC;
  
  width: 111px;
  height: 54px;
  left: 298px;
  top: 630px;

  background: #0B5DD7;
  border-radius: 8px;
  border: none;
}

.signup-button:hover {
  cursor: pointer;
  background-color: #07306D
}

/* SIDEBAR */
.sidebar {
  position: absolute;
  width: 78px;
  left: 0px;
  top: 0px;
  bottom: 0px;

  /* Secondary */

  background: #D7840B;
  border-radius: 0px 20px 0px 0px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.sidebar-button-group {
  /* position: relative; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  height: 276px;
  margin-top: 0px;
  /* overflow: hidden; */
  /* border-bottom: 3px solid transparent; */
  
}

.sidebar-button {
  /* position: absolute; */
  width: 42px;
  height: 42px;
  left: 0px;
  top: 0px;
  /* Fixed Footer */

  filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.16));
  /* overflow: hidden; */
  /* border: 20px solid transparent; */
  /* border-bottom: 3px solid transparent; */
  
}

.sidebar-button:hover {
  /* display: block;
  position: absolute; */
  transform: scale(1.2);
  /* width: 38px;
  height: 38px; */
  left: 0px;
  top: 0px;
  filter: drop-shadow(0px 6px 8px rgba(0, 0, 0, 0.45));
  cursor: pointer;
  /* border-bottom: 3px solid transparent; */
  /* overflow: hidden; */
}

.sidebar-button-clicked {
  /* display: block;
  position: absolute; */
  transform: scale(1.2);
  /* width: 38px;
  height: 38px; */
  left: 0px;
  top: 0px;
  filter: drop-shadow(0px 6px 8px rgba(0, 0, 0, 0.45));
  cursor: pointer;
  /* border-bottom: 3px solid transparent; */
  /* overflow: hidden; */
}

/* WATCHLIST */

.watchlist {
  /* border: 1px solid; */
  display: flex;
  flex-direction: row;
  /* margin-top: 0; */
  position: relative;
  /* width: 28vw; */
  /* width: 408px; */
  height: 908px;
  /* left: 78px; */
  /* top: 18.8px; */

  border-radius: 0px 20px 20px 0px;
  /* margin: 0;
  padding: 0; */
}

.tab-heading {
  width: 194px;
  height: 56px;

  /* Desktop Headings/H2 */

  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  font-size: 52px;
  line-height: 56px;
  /* identical to box height, or 108% */

  display: flex;
  align-items: flex-end;

  /* Greyscale/Dark Grey */

  color: #757D87;


  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;

  margin-left: 32px;
}

.watchlist-search {
  display: flex;
  flex-direction: row;
  height: 72px;
}

.watchlist-search > img {
  margin-left: 19px;
}
.watchlist-search > img:hover {
  cursor: pointer;
}

.watchlist-button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* width: 344px; */
  margin-left: 102px;
  margin-right: 134.5px;
  margin-top: 43px;
  margin-bottom: 14px;
}

.watchlist-button {
  margin: 0px;
  padding: 0px;
  /* background-color: none; */
  background: transparent;
  border: none;
  /* width: 49px;
  height: 17px; */

  /* Labels/Default (Bold) */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */


  /* Utilities/iOS/Keyboard Buttons (Dark) */

  color: #ACB4BE;


  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.watchlist-button:hover {
  cursor: pointer;
}

.watchlist-button-selected {
  border: none;
  background: transparent;
  margin: 0px;
  padding: 0px;
  /* width: 66px;
  height: 17px; */

  /* Labels/Default (Bold) */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */


  /* Brand/Primary */

  color: #0B5DD7;


  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.list-container {
  position: absolute;
  width: 408px;
  /* width: calc(100% - 78px); */
  /* height: 548px; */
  /* left: 110px; */
  /* top: 289px; */

  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
}

.list-container > p {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 265px;
  height: 34px;

  /* Labels/Default (Bold) */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;

  /* Utilities/iOS/Keyboard Buttons (Dark) */

  color: #ACB4BE;


  /* Inside auto layout */

  /* flex: none;
  order: 0;
  flex-grow: 0; */
}

.watchlist-row-element {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content:space-between;
  width: 344px;
  /* width: calc(100% - 64px); */
  height: 72px;

  border-radius: 20px;

  /* Inside auto layout */

  /* flex: none; */
  order: 0;
  flex-grow: 0;
  margin-bottom: 8px;
  /* margin-right: 32px; */
}

.watchlist-row-element:hover {
  cursor: pointer;
}

.watchlist-row-subelement-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 16px;
}
.watchlist-row-subelement-right {
  display: flex;
  flex-direction: column;
  align-items:flex-end;
  margin-RIGHT: 16px;
}

.subelement-ticker {
  margin-bottom: 4px;
  /* Body Text/Default (Bold) */

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */


  color: #000000;
}

.subelement-shortname {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  
  /* Greyscale/Dark Grey */
  
  color: #757D87;
}

.subelement-price {
  margin-bottom: 4px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  text-align: right;

  color: #000000;
}

.subelement-change {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-align: right;
  color: #757D87;
}

.watchlist-left {
  width: 408px;
}

.watchlist-right {
  /* display: flex;
  flex-direction: column; */
  /* height: 300px; */
  height: 100vh;
  /* width: 1440px; */
  /* overflow-x: none; */
  overflow: scroll;
  /* scroll-behavior: smooth; */
  /* margin-right: 32px; */
}



/* SEARCHBAR */

.searchbar > div > div  > input {
  /* position: relative; */
  /* Search Input */


  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  width: 201px;
  height: 32.98px;


  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  outline: none;
  /* border: none; */
  background-color: transparent;

  /* border-bottom: 10px;
  border-top:#07306D; */

  border-bottom: 1px solid #CED5DE;

  border-top: none;
  border-left: none;
  border-right: none;

  background-image: url("./assets/search.png");
  background-size: 19.47px;
  background-repeat: no-repeat;
  background-position: 5px center;
}

.searchbar > div > div > input[type="search"] {
  /* border: none;
  background-color: transparent; */
  margin-left: 32px;
  padding: 7px 8px 7px 30px;
  /* font-size: 16px; */
  /* color: inherit;
  border: 1px solid black; */
  /* border-radius: inherit; */
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  /* line-height: 24px; */
}

[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  appearance: none;
  height: 10px;
  width: 10px;
  background-image: url("./assets/close.svg");
  background-size: 10px 10px;
}

.searchbar > div > div > input:hover {
  outline: none;
  /* pointer-events: none; */
}

.search-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* position: relative; */
}

/* .search-drop-container {
  position: relative;
} */

.dropdown {
  background-color: #F9F9F9;
  display: flex;
  flex-direction: column;
  border: 1px solid gray;
  position: absolute;
  cursor: pointer;
  /* width: 33vw; */
  z-index: 1;
  margin-left: 32px;
  width: 201px;
}

.dropdown-row{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #F9F9F9;
  z-index:10;
}

.dropdown-row:hover {
  background-color: #e1e1e1;
}

.quick-price {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 32px;
  /* color: #068B03; */

  filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, 0.15));
  border-radius: 4px;
}

.main-quick-price {
  margin-left: 5px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  
  /* text-align: right; */
  
  /* Semantic/Success */
  
  color: #757D87;
}

.secondary-price {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  margin-left: 8px;
  color: #757D87;
}

/* CHART */
.chart {
  display: flex;
  flex-direction: column;
  width: calc(100vw - 505px);
  height: 479px;
  /* left: 486px; */
  /* margin-right: 19px; */
  margin-top: 32px;

  background: #FFFFFF;
  border-radius: 20px;
}

.chart-quote {
  display: flex;
  flex-direction: column;
  margin-left: 32px;
  height: 68px;
  /* align-items: flex-end; */
}

.chart-shortname {
  /* font-size: 1px; */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #757D87;
  margin-top: 8px;
}

.symbol-price {
  margin: 0px;
  display: flex;
  flex-direction: row;
  align-items: top;
}

.h4-watchlist {
  margin: 0;
  /* position: absolute; */
  /* width: 63px; */
  /* height: 32px; */
  /* left: 32px; */
  /* top: 32px; */

  /* Desktop Headings/H4 */

  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  /* line-height: 32px; */
  /* identical to box height, or 114% */


  color: #000000;
}

.chart-quick-price {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 32px;
  /* color: #068B03; */

  /* filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, 0.15));
  border-radius: 4px; */
}

.chart-main-price {
  margin: 0px;
  /* position: absolute;
  width: 60px;
  height: 32px;
  left: 0px;
  top: 0px; */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  /* line-height: 32px; */
  /* identical to box height, or 178% */


  color: #068B03;

}

.chart-secondary-price {
  margin: 0px;
  /* position: absolute;
  width: 50px;
  height: 32px;
  left: 0px;
  top: 0px; */
  
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  /* line-height: 32px; */
  margin-left: 8px;
  /* identical to box height, or 178% */
  
  
  color: #068B03;
}

.chart-buttons {
  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  margin-left: 32px;

  /* position: absolute; */
  width: 323px;
  height: 48px;
  left: 32px;
  top: 120px;
}

.chart-buttons > button {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  /* Pieces / Tabbed Highlight Item */

  border: 0px;
  background: none;


  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;

  width: 53.83px;
  height: 48px;

  /* Greyscale/Grey */

  border-bottom: 1px solid #CED5DE;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 1;
}

.chart-buttons > button:hover {
  cursor: pointer;
}

.stockchart {
  display:flex;
  width: calc(100%-32px);
  /* width: 100%; */
  height: 100%;
  margin-left:32px;
  margin-right: 32px;
}

/* NEWS */
.news {
  display: flex;
  flex-direction: column;
  width: calc(100vw - 505px);
  /* height: 100vh; */
  margin-bottom: 96px;
  /* left: 486px; */
  /* margin-right: 19px; */
  margin-top: 32px;
  padding-bottom: 32px;
  /* padding-left: 32px;
  padding-right: 32px; */
  /* margin-bottom: 32px; */
  /* padding: 0px 32px 0px 32px; */

  background: #FFFFFF;
  border-radius: 20px;
}

.news-header {
  /* box-sizing: border-box; */

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 32px 32px;
  padding: 8px 16px;
  /* gap: 8px; */

  /* width: 427px;
  height: 48px; */

  /* Brand/Primary */

  border-bottom: 4px solid #0B5DD7;

  /* Inside auto layout */

  /* flex: none;
  order: 1;
  flex-grow: 1; */

  /* width: 39px;
  height: 17px; */

  /* Labels/Default (Bold) */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  /* line-height: 17px; */
  /* identical to box height */


  /* Greyscale/Black */

  color: #252930;


  /* Inside auto layout */

  /* flex: none;
  order: 0;
  flex-grow: 0; */
}

.news-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 28px 32px 0px 32px;
}

::-webkit-scrollbar {
  display: none;
}

.news-content > img {
  margin: 0px 22.5px 0px 32px;
}

.hours {
  /* width: 67px;
  height: 15px; */

  /* Labels/Small */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  /* line-height: 15px; */
  /* identical to box height */


  /* Greyscale/Dark Grey */

  color: #757D87;


  /* Inside auto layout */

  /* flex: none;
  order: 1;
  flex-grow: 0; */
}

.news-text {
  display: flex;
  flex-direction: column;
  margin-left: 22.5px;
  margin-right: 32px;
}

.news-title {
  /* width: 554px;
  height: 64px; */

  /* Body Text/Default */

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  /* line-height: 32px; */
  /* or 152% */


  color: #000000;


  /* Inside auto layout */

  /* flex: none;
  order: 0;
  flex-grow: 0; */
}

.news-title > span {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  /* line-height: 32px; */
  /* or 152% */


  color: #000000;
}

/* PORTFOLIO */
.portfolio {
    /* border: 1px solid; */
    display: flex;
    flex-direction: row;
    /* margin-top: 0; */
    position: relative;
    /* width: 28vw; */
    /* width: 408px; */
    height: 908px;
    /* left: 78px; */
    /* top: 18.8px; */
  
    border-radius: 0px 20px 20px 0px;
    /* margin: 0;
    padding: 0; */
}

.portfolio-left{
    width: 408px;
}

.portfolio-right {
    /* display: flex;
    flex-direction: column; */
    /* height: 300px; */
    height: 100vh;
    /* width: 1440px; */
    /* overflow-x: none; */
    overflow: scroll;
    /* scroll-behavior: smooth; */
    /* margin-right: 32px; */
}

h5 {
  margin: 0px 0px 8px 32px;
  /* width: 312px;
  height: 24px; */

  /* Desktop Headings/H5 */

  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  /* line-height: 24px; */
  /* identical to box height, or 120% */


  /* Secondary */

  color: #D7840B;


  /* Inside auto layout */

  /* flex: none;
  order: 0;
  flex-grow: 0; */
}

.h4-portfolio {
  margin: 0px 0px 8px 32px;
  /* position: absolute; */
  /* width: 63px; */
  /* height: 32px; */
  /* left: 32px; */
  /* top: 32px; */

  /* Desktop Headings/H4 */

  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  /* identical to box height, or 114% */


  color: #000000;
}

.account-value {
  margin: 0px 0px 4px 32px;
  /* width: 313px;
  height: 16px; */

  /* Labels/Default */

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  /* line-height: 16px; */
  /* identical to box height, or 114% */


  /* Greyscale/Dark Grey */

  color: #757D87;


  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.account-value-summary {
  margin: 44px 0px 0px 32px;
  display: flex;
  flex-direction: column;
}

.account-value-summary > p {
  border-bottom: 2px solid #CED5DE;
  display: flex;
  flex-direction: row;
  align-items:center;
  justify-content: space-between;
  width: 288px;
  margin: 8px 0px;
}

.account-value-summary > p > span {
  /* width: 111px;
  height: 24px; */

  /* Labels/Large */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  /* line-height: 24px; */
  /* identical to box height, or 133% */


  color: #000000;


  /* Inside auto layout */

  /* flex: none;
  order: 0;
  flex-grow: 0; */
}

.portfolio-left > .quick-price > .secondary-price {
  margin: 0px 4px 0px 0px;
}



/* ANALYTICS */

.analytics {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
}

.analytics > h1 {
  margin-bottom: 10px;
}

.analytics-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50vw;
}

.form-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  margin-right: 110px;
}

.form-container-input {
  display: flex;
  flex-direction: row;
  margin: 10px;
}

.form-container-input-middle {
  display: flex;
  flex-direction: row;
  margin: 10px;
  padding: 0 20px;
  border-right: 1px solid rgb(0, 0, 0, 0.1);
  border-left: 1px solid rgb(0, 0, 0, 0.1);
}

.form-button:disabled{
  background:  #F9F9F9;
  color: rgb(0, 0, 0, 0.1);
  cursor: default;
}

/* ABOUT */
.about {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* CONTACT */
.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* LEARNING */
.learning {
  display: flex;
  flex-direction: column;
  align-items: center;
}